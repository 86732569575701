<template>
  <div id="userDiv">
    <!-- <AActiveSwitch v-model="showUsers" /> -->
    <!-- <Dropdown optionLabel="type_name" placeholder="Task Type" class="input" /> -->
    <template v-if="!isLoading">
      <div
        style="width: 550px; max-width: 90vw"
        :class="'flex col-12 align-items-center justify-content-between user-bottom-border ' + (u.system_active === 0 ? 'opacity-50' : '')"
        v-for="u in filtered(users)"
        :key="u.uid"
      >

        <div class="col-1 flex-grow-0">
          <Avatar
            :image="u.profile_picture"
            :label="!u.profile_picture ? getIntials(u) : null"
            :style="{
              'background-color': getColorFromName(u),
              color: '#ffffff',
            }"
            strokeColor="#6dd230"
            innerStrokeColor="#ffffff"
            :radius="25"
            class="mr-2"
            size="large"
            shape="circle"
          />
        </div>
        <div class="col">{{ u.name }} {{ u.surname }}</div>
        <div
          v-if="u.title"
          class="col"
        >{{ u.title }}</div>
        <div
          v-else
          class="col font-light"
        >N/A</div>
        <div class="col-3">
          <template v-if="u.estimated_hours">
            <div>
              {{ !u.hours ? "00:00" : u.hours }} / {{ u.estimated_hours }}
            </div>
            <div class="flex align-items-center mt-1">
              <div
                class="surface-300 border-round overflow-hidden w-full"
                style="height: 6px"
              >
                <div
                  class="h-full"
                  :style="getProgressStyle(u)"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="col-1 flex-grow-0">
          <AActiveSwitch
            :disabled="u.system_active === 0"
            @change="toggleUser(u.uid)"
            v-model="u.active"
          />
        </div>
      </div>
    </template>
    <div
      style="width: 550px; max-width: 90vw"
      v-else
      class="flex col-12 align-items-center justify-content-between"
      v-for="u in 4"
      :key="u"
    >
      <Skeleton
        class="col"
        height="50px"
        borderRadius="6px"
      />
    </div>
  </div>
</template>

<script>
import {
  fetchTaskUsers,
  fetchTask,
  state,
  fetchSettingsUsers,
  fetchTaskTypes,
} from "../../../services/data_service.js";
export default {
  props: {
    active: {
      type: Boolean,
    },
    searchQuery: {
      type: String,
    },
  },
  data() {
    return {
      fetchedTaskTypes: false,
      isLoading: false,
      systemUsers: [],
      userDivHeight: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      fetchTaskTypes(1),
      fetchTaskUsers(this.task_uid),
      fetchSettingsUsers(),
      fetchTask(this.task_uid),
    ]);

    this.systemUsers = JSON.parse(JSON.stringify(state.settingsUsers));
    this.systemUsers = this.systemUsers.filter((a) => a.active == 1);
    this.isLoading = false;

    this.$nextTick(() => {
      var userDiv = document.getElementById("userDiv");
      var height = userDiv.offsetHeight;
      userDiv.style.height = height + "px";
      var width = userDiv.offsetWidth;
      userDiv.style.width = width + "px";
    });
  },
  computed: {
    task_uid() {
      return this.$store.getters.selectedObject.uid;
    },
    users() {
      var arr = JSON.parse(JSON.stringify(state.taskUsers));

      var users = this.systemUsers.map((p) => p);
      users.forEach((u) => {
        if (!arr.find((b) => b.uid == u.uid)) {
          u.active = 0;
          arr.push(u);
        }
      });

      arr.forEach((u) => {
        // if u is not is users, make system_active 0
        if (!users.find((b) => b.uid == u.uid)) {
          u.system_active = 0;
        } else {
          u.system_active = 1;
        }
      });

      arr?.sort((a, b) => {
        const aHasEstimatedHours = "estimated_hours" in a;
        const bHasEstimatedHours = "estimated_hours" in b;
        const aHasDepartment = a.departments?.some(
          (dept) => dept.uid === this.task?.department
        );
        const bHasDepartment = b.departments?.some(
          (dept) => dept.uid === this.task?.department
        );

        if (aHasEstimatedHours && !bHasEstimatedHours) return -1;
        if (bHasEstimatedHours && !aHasEstimatedHours) return 1;

        if (aHasDepartment && !bHasDepartment) return -1;
        if (bHasDepartment && !aHasDepartment) return 1;

        return a.name.localeCompare(b.name);
      });

      return arr;
    },
    task() {
      return state.task;
    },
    linkedTaskType() {
      let linkedType;
      state.taskTypes.forEach((type) => {
        if (type.uid == this.task.task_type) {
          linkedType = type;
        }
      });
      if (linkedType == undefined) {
        this.getTaskTypes();
        state.taskTypes.forEach((type) => {
          if (type.uid == this.task.task_type) {
            linkedType = type;
          }
        });
      }
      return linkedType;
    },
  },

  methods: {
    async getTaskTypes() {
      try {
        state.taskTypes.some((task) => {
          if (task.uid !== this.task.task_type && !this.fetchedTaskTypes) {
            fetchTaskTypes(0);
            this.fetchedTaskTypes = true;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    filtered(users) {
      let arr = [];

      this.systemUsers.map((user) => {
        if (this.linkedTaskType) {
          this.linkedTaskType.departments?.forEach((department) => {
            if (department.uid == user.primary_department_uid) {
              arr.push(user);
            }
          });
        }

        return arr;
      });
      users?.forEach((u) => {
        if (!arr.find((b) => b.uid == u.uid)) {
          arr.push(u);
        }
      });
      if (this.searchQuery) {
        arr = arr.filter((user) => {
          // search on name surname username and email
          return (
            user.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            user.surname
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            user.username
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            user.email?.email
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          );
        });
      }
      return arr;
    },
    getProgressStyle(task) {
      if (task.progress_bar >= 100) {
        return "background: #fe4d97;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;";
      } else {
        return (
          "border-bottom-left-radius: 4px;background: #6dd230;width:" +
          task.progress_bar +
          "%"
        );
      }
    },
    toggleUser(user_uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.task_uid +
            "/assign/" +
            user_uid,
          {}
        )
        .then((response) => {
          this.isLoading = false;
          fetchTaskUsers(this.task_uid);
          fetchTask(this.task_uid);
          this.task;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  watch: {
    active(n, o) {
      if (n == true) {
        this.systemUsers = JSON.parse(JSON.stringify(state.settingsUsers));
      } else {
        this.systemUsers = JSON.parse(
          JSON.stringify(state.settingsUsers.filter((a) => a.active == 1))
        );
      }
    },
  },
};
</script>

<style scoped>
.user-bottom-border {
  border-bottom: 1px solid #dee2e6;
}
</style>
